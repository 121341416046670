<template>
    <div>
        <h1 class="mb-1 mt-50 text-danger">
            Hello {{userData.fullName}}, This is admin dashboard page
        </h1>
    </div>
</template>
<script>
export default {
    data() {
        return {
        userData: JSON.parse(localStorage.getItem('userData'))
        }
    },
}
</script>